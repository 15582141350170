//* 1. PKS config
// import logoPKS from "./assets/Logo/pks.png";
// export const logo = logoPKS;
// export const fetchOnStart = true;
// export const listPositionForAgency = 0;
// export const minLinesAtStop = 4;
// export const reducePolylinesPoints = 3;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://pksonline.pl:8443/oba-api";
// export const appName = "PKS POZNAŃ";
// export const zoomRange = 0.01;
// export const apiKey = "web";
// export const siriApiKey = "web";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 10.5;
// export const zoomBusOnlyCircle = 8.8;
// export const clustersZoom = 9;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_PKS";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "pl";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = true;

//* 2. SweetWater config
// import logoSweetWater from "./assets/Logo/SweetWater.png";
// export const logo = logoSweetWater;
// export const fetchOnStart = true;
// export const listPositionForAgency = 0;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 1;
// export const showChangeLanguage = false;
// export const apiBaseURL = "https://sw.tracktrolley.com/oba-api-sw";
// export const appName = "Track Trolley Sweetwater";
// export const zoomRange = 0.005;
// export const apiKey = "org.onebusaway.iphone";
// export const siriApiKey = "org.onebusaway.iphone";
// export const zoomEnableSwitch = 17;
// export const zoomForSelectedBusStation = 16.5;
// export const zoomFullIcons = 13;
// export const zoomBusOnlyCircle = 12.5;
// export const clustersZoom = 13;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_SW";
// export const routeListButtonWidth = "70px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "en";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//* 3. Swarzedz config
// import logoSwarzedz from "./assets/Logo/Swarzedz.png";
// export const logo = logoSwarzedz;
// export const fetchOnStart = true;
// export const listPositionForAgency = 2;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 2;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://iplaner.pl:8443/onebusaway-api-webapp-production";
// export const appName = "SPK OneBusAway";
// export const zoomRange = 0.01;
// export const apiKey = "web";
// export const siriApiKey = "web";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 13.5;
// export const zoomBusOnlyCircle = 11.5;
// export const clustersZoom = 11;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_Swarzedz";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "pl";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//* 4. City of Doral config
// import logoDoral from "./assets/Logo/Doral.png";
// export const logo = logoDoral;
// export const fetchOnStart = true;
// export const listPositionForAgency = 0;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 1;
// export const showChangeLanguage = false;
// export const apiBaseURL = "https://oba.tracktrolley.com/oba-api";
// export const appName = "Track Trolley Doral";
// export const zoomRange = 0.005;
// export const apiKey = "org.onebusaway.iphone";
// export const siriApiKey = "org.onebusaway.iphone";
// export const zoomEnableSwitch = 17;
// export const zoomForSelectedBusStation = 16.5;
// export const zoomFullIcons = 12.8;
// export const zoomBusOnlyCircle = 12.1;
// export const clustersZoom = 12;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_Doral";
// export const routeListButtonWidth = "74px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "en";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//* 5. City of Miami Beach config
import logoMiamiBeach from "./assets/Logo/MiamiBeach.svg";
export const logo = logoMiamiBeach;
export const fetchOnStart = true;
export const listPositionForAgency = 0;
export const minLinesAtStop = 1;
export const reducePolylinesPoints = 1;
export const showChangeLanguage = false;
export const apiBaseURL = "https://comb.tracktrolley.com/oba-api-comb";
export const appName = "Miami Beach Trolley";
export const zoomRange = 0.005;
export const apiKey = "org.onebusaway.iphone";
export const siriApiKey = "org.onebusaway.iphone";
export const zoomEnableSwitch = 17;
export const zoomForSelectedBusStation = 16.5;
export const zoomFullIcons = 14.5;
export const zoomBusOnlyCircle = 12.7;
export const clustersZoom = 13;
export const locateMeZoom = 18;
export const rootStateStorage = "rootState_MB";
export const routeListButtonWidth = "88px";
export const showDepartureBoardLink = process.env.NODE_ENV === "development" ? true : false;
export const defaultLanguage = "en";
export const themeProviderCoMB = true;
export const showOnlyTheLongest = false;

//* 6. Miami-Dade config
// import logoMiamiDade from "./assets/Logo/MiamiDade.png";
// export const logo = logoMiamiDade;
// export const fetchOnStart = true;
// export const listPositionForAgency = 2;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 3;
// export const showChangeLanguage = false;
// export const apiBaseURL = "https://oba.tracktrolley.com/oba-api";
// export const appName = "Track Trolley Miami-Dade";
// export const zoomRange = 0.005;
// export const apiKey = "org.onebusaway.iphone";
// export const siriApiKey = "org.onebusaway.iphone";
// export const zoomEnableSwitch = 17;
// export const zoomForSelectedBusStation = 16.5;
// export const zoomFullIcons = 15.8;
// export const zoomBusOnlyCircle = 10.5;
// export const clustersZoom = 12;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_MD";
// export const routeListButtonWidth = "58px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "en";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//* 7. ZTM Poznan config
// import logoZTM_Poznan from "./assets/Logo/ZTM_Poznan.svg";
// export const logo = logoZTM_Poznan;
// export const fetchOnStart = true;
// export const listPositionForAgency = 2;
// export const minLinesAtStop = 4;
// export const reducePolylinesPoints = 4;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://iplaner.pl:8443/onebusaway-api-webapp-ztm";
// export const appName = "ZTM POZNAŃ";
// export const zoomRange = 0.01;
// export const apiKey = "org.onebusaway.iphone";
// export const siriApiKey = "org.onebusaway.iphone";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 15.8;
// export const zoomBusOnlyCircle = 13.5;
// export const clustersZoom = 12;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_ZTM_Poznan";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "pl";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = true;

//* 8. City of Fort Lauderdale config
// import logoFortLauderdale from "./assets/Logo/logoFortLauderdale.png";
// export const logo = logoFortLauderdale;
// export const fetchOnStart = true;
// // export const listPositionForAgency = 0; //* Base Api
// export const listPositionForAgency = 1; //* Second Api
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 1;
// export const showChangeLanguage = false;
// // export const apiBaseURL = "https://fll.tracktrolley.com/oba-api-fll"; //* Base Api
// export const apiBaseURL = "https://oba.tracktrolley.com/oba-api"; //* Second Api
// export const appName = "Track Trolley Fort Lauderdale";
// export const zoomRange = 0.005;
// export const apiKey = "org.onebusaway.iphone";
// export const siriApiKey = "org.onebusaway.iphone";
// export const zoomEnableSwitch = 17;
// export const zoomForSelectedBusStation = 16.5;
// export const zoomFullIcons = 15.8;
// export const zoomBusOnlyCircle = 10.5;
// export const clustersZoom = 12;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_FL";
// export const routeListButtonWidth = "58px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "en";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//! 9. Local ONLY! Kombus config
// import logoKombus from "./assets/Logo/logoKombus.png";
// export const logo = logoKombus;
// export const fetchOnStart = true;
// export const listPositionForAgency = 0;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 2;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://iplaner.pl:8443/onebusaway-api-webapp-production";
// export const appName = "OBA";
// export const zoomRange = 0.01;
// export const apiKey = "web";
// export const siriApiKey = "web";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 13.5;
// export const zoomBusOnlyCircle = 11.5;
// export const clustersZoom = 11;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_Kombus";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "pl";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//! 10. Local ONLY! Środa Wlkp. config
// import logoSroda from "./assets/Logo/logoSroda.png";
// export const logo = logoSroda;
// export const fetchOnStart = true;
// export const listPositionForAgency = 1;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 2;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://iplaner.pl:8443/onebusaway-api-webapp-production";
// export const appName = "OBA Środa Wlkp.";
// export const zoomRange = 0.01;
// export const apiKey = "web";
// export const siriApiKey = "web";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 13.5;
// export const zoomBusOnlyCircle = 11.5;
// export const clustersZoom = 11;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_Sroda";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "pl";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//@ 11. Local ONLY! GitHub config
// import logoOBA from "./assets/Logo/logoOBA.png";
// export const logo = logoOBA;
// export const fetchOnStart = true;
// export const listPositionForAgency = 1;
// export const minLinesAtStop = 1;
// export const reducePolylinesPoints = 2;
// export const showChangeLanguage = true;
// export const apiBaseURL = "https://api.pugetsound.onebusaway.org";
// export const appName = "OneBusAway React App";
// export const zoomRange = 0.01;
// export const apiKey = "test";
// export const siriApiKey = "";
// export const zoomEnableSwitch = 14;
// export const zoomForSelectedBusStation = 14;
// export const zoomFullIcons = 13.5;
// export const zoomBusOnlyCircle = 11.5;
// export const clustersZoom = 11;
// export const locateMeZoom = 18;
// export const rootStateStorage = "rootState_OBA";
// export const routeListButtonWidth = "42px";
// export const showDepartureBoardLink = true;
// export const defaultLanguage = "en";
// export const themeProviderCoMB = false;
// export const showOnlyTheLongest = false;

//* Common Settings
// Fetch intervals
export const fetchInterval = process.env.NODE_ENV === "development" ? 100 * 1000 : 30 * 1000; //!fetchInterval (15s-30s)
export const fetchIntervalStop = process.env.NODE_ENV === "development" ? 100 * 1000 : 30 * 1000; //!fetchInterval (30s)
// Max number of calls
export const maximumNumberOfCallsOnwards = 15;

//* Before Deployment Settings - select one
// i18n settings: "" | "/ztm" | "/wroclaw"
export const i18nPrefix = "";
//* basename settings: "" | "/ztm" | "/wroclaw"
export const reactRouterBaseLine = "";
//* Development Info
export const showDevInfo = process.env.NODE_ENV === "development" ? true : false; //! showDevInfo;

//* Add homepage to package.json
//* https://pks.goeuropa.net
//* "homepage": "https://goeuropa.net/ztm",
//* "homepage": "https://goeuropa.net/swarzedz2",
//* "homepage": "https://goeuropa.net/wroclaw",

//* https://dt.goeuropa.net
//* https://comb.goeuropa.net
//* https://mdt.goeuropa.net
//* https://sw.goeuropa.net

//@ Bootstrap_5 breakpoint: large, lg>=992
//^ Breakpoints
const breakpoints = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "992px",
  xl: "1280px",
  "2xl": "1536px",
};

export const devices = {
  xs: `(min-width: ${breakpoints.xs})`,
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
  xl: `(min-width: ${breakpoints.xl})`,
  "2xl": `(min-width: ${breakpoints["2xl"]})`,
};
