import React from "react";
import styled from "styled-components";

import { themeProviderCoMB } from "../../config";
import useNetworkStatus from "../Services/useNetworkStatus";

const FooterContainer = styled.div`
  width: 100%;
  height: var(--constantFooterHeight);
`;

const FooterCoMB = styled.div`
  width: 100%;
  height: var(--constantFooterHeight);
  background-color: whitesmoke;
`;

export const FooterContent = (): JSX.Element => (
  <div>
    &copy; {new Date().getFullYear()} Copyright:{" "}
    <a
      className={themeProviderCoMB ? "text-white" : "text-dark"}
      href="https://goeuropa.eu"
      target="_blank"
      rel="noreferrer"
    >
      <span className="span_bold">goEuropa</span>
    </a>
  </div>
);

const Footer = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();

  return (
    <React.Fragment>
      <FooterContainer>
        {isMobile && themeProviderCoMB ? (
          <FooterCoMB>
            <span>{""}</span>
          </FooterCoMB>
        ) : (
          <footer
            className={`d-flex flex-wrap justify-content-center border-bottom text-white py-3 ${
              themeProviderCoMB ? "bg-info" : "bg-secondary"
            }`}
          >
            <FooterContent />
          </footer>
        )}
      </FooterContainer>
    </React.Fragment>
  );
};

export default Footer;
