import Leaflet from "leaflet";

import CoMBiconsSVG from "../assets/Icons_CoMB";

const {
  icon33417: Icon33417,
  icon33418: Icon33418,
  icon33419: Icon33419,
  icon33422: Icon33422,
  icon67978: Icon67978,
  iconNotMonitored: IconNotMonitored,
} = CoMBiconsSVG;

const icon33417 = new Leaflet.Icon({
  iconUrl: Icon33417,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const icon33418 = new Leaflet.Icon({
  iconUrl: Icon33418,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const icon33419 = new Leaflet.Icon({
  iconUrl: Icon33419,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const icon33422 = new Leaflet.Icon({
  iconUrl: Icon33422,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const icon67978 = new Leaflet.Icon({
  iconUrl: Icon67978,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const iconNotMonitored = new Leaflet.Icon({
  iconUrl: IconNotMonitored,
  iconAnchor: [14, 14],
  popupAnchor: [0, -14],
  iconSize: [28, 28],
  tooltipAnchor: [14, 0],
  className: "bus_icon",
});

const iconsCoMB = { icon33417, icon33418, icon33419, icon33422, icon67978, iconNotMonitored };

export default iconsCoMB;
